<!-- 细胞多样性 -->
<template>
  <div style="padding: 20px 24px 40px 24px">
    <div class="globalText">
      淋巴细胞种类愈多，均一性愈好，则疾病抵抗力愈强。
    </div>
    <div class="resultItem">
      <div style="margin-bottom: 16px">
        <span class="globalText middle" style="line-height: 30px">您的T细胞多样性指数（Shannon's entropy）为<span
            style="color: #ff830f; margin-left: 4px">{{ this.shannonIndex }}</span></span>
      </div>
      <div>
        <span class="globalText middle" style="line-height: 30px">基于人群T细胞多样性大数据，您目前的多样性评估结果为<span
            style="color: #ff830f; margin-left: 4px">{{ this.shannonRes }}</span></span>
      </div>
    </div>
    <div class="navImg">
      <img :src="osspath + shannonPic" class="imgTransition" alt="" style="width: 100%" />
    </div>
    <div class="intro">T细胞多样性（Shannon's entropy）导航图</div>
    <div class="globalTitle" style="margin: 30px 0 16px 0; color: #ff830f">
      指标解释
    </div>
    <div class="globalText">
      <div style="margin-bottom: 14px">
        T细胞多样性是基于免疫组库技术，利用分离后的T细胞受体β链DNA作为模板，通过多重PCR检测功能性T细胞受体的V(D)J基因重组，使用二代高通量基因测序平台，结合生物信息学分析算法对数据进行分析，让T细胞多样性指数数字化、可视化。
      </div>
      <div>
        <span>本报告中T细胞多样性指数为香农熵（Shannon's
          entropy）反映的是T细胞种类与均一性。</span>
        <span style="color: #ff830f">指数越高说明T细胞多样性越好，代表免疫储备越充足；反之则代表多样性较差，免疫储备有所不足，或是处于急性感染恢复期、慢性炎症状态中。</span>
      </div>
    </div>
    <div class="globalTitle" style="margin: 30px 0 16px 0; color: #ff830f">
      T细胞克隆TOP20分析
    </div>
    <div class="globalText">
      <div style="margin-bottom: 14px">
        根据受检者本次样品检测结果，含量最多的前20种TCR克隆占总检出克隆比例为
        <span style="color: #ff830f">{{ top20Freq }}%</span> 。
      </div>
      <div>
        将前20种TCR克隆与VDJdb、McPAS-TCR等数据库比较，对人类疱疹病毒1/2、EB病毒、巨细胞病毒、腺病毒、人类乳头瘤病毒、丙肝病毒、流感病毒、人类冠状病毒HKU1、新冠病毒相关的TCR克隆进行功能注释。
      </div>
    </div>
    <div class="star">
      <span>
        <span class="xing">*</span>注：克隆占比为检出的TCR克隆数占总检出TCR克隆数的比例。“-”表示数据库中未记载与该TCR克隆相关的功能。
      </span>
    </div>
    <div class="globalText">
      <div style="display: flex">
        <div class="top" style="width: 55%">TCR克隆序列(氨基酸)</div>
        <div class="top" style="width: 20%; text-align: center">占比</div>
        <div class="top" style="width: 25%; text-align: center">功能注释</div>
      </div>
      <div style="display: flex" v-for="(item, index) in topList" :key="index">
        <div class="content" style="width: 55%; border-right: 0">
          {{ item.CDR3 }}
        </div>
        <div class="content" style="width: 20%; border-right: 0; text-align: center">
          {{ item.freq }}
        </div>
        <div class="content" style="width: 25%; text-align: center">
          {{ item.function }}
        </div>
      </div>
    </div>
    <div class="globalText more" @click="showMore()">
      {{ tip }}
      <div :class="isShow ? 'scroll' : 'noscroll'" style="margin-left: 3px">
        <van-icon name="arrow-down" color="#999999" size="14px" />
      </div>
    </div>
  </div>
</template>
<script>
import { osspath } from "../../../util/config";
export default {
  data() {
    return {
      shannonIndex: "",
      shannonRes: "",
      shannonPic: "",
      top20Freq: "", //tcr频率
      topList: [],
      top20TCR: [],
      isShow: false, //序列信息折叠
      tip: "查看更多",
      osspath: osspath,
    };
  },
  created() { },
  mounted() {
    // 置顶
    window.scroll({ top: 0, left: 0 });

    let data = JSON.parse(localStorage.getItem("report"));
    this.shannonIndex = data.shannonIndex;
    this.shannonRes = data.shannonRes;
    this.shannonPic = data.shannonPic;
    this.top20Freq = data.top20Freq;
    this.top20TCR = data.top20TCR;

    if (this.top20TCR.length <= 4) {
      this.topList = this.top20TCR;
    } else {
      // 取前四位
      this.topList = this.top20TCR.slice(0, 4);
    }
  },
  computed: {},
  methods: {
    showMore() {
      // 序列信息展开 / 或折叠
      if (this.topList.length == this.top20TCR.length) {
        let topList = this.topList;
        this.topList = topList.splice(0, 4);
        this.isShow = false;
        this.tip = "查看更多";
      } else {
        let topList = this.topList;
        if (topList.length < 4) {
          topList = topList.concat(this.top20TCR.slice(topList.length, 4));
        } else {
          topList = topList.concat(
            this.top20TCR.slice(topList.length, 4 + topList.length)
          );
        }
        if (topList.length == this.top20TCR.length) {
          this.tip = "收起";
          this.isShow = true;
        } else {
          this.isShow = false;
          this.tip = "查看更多";
        }
        this.topList = topList;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resultItem {
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: 30px 0;
}

.navImg {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.intro {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  margin-top: 13px;
}

.star {
  font-size: 12px;
  color: #e02020;
  line-height: 17px;
  margin: 16px 0 12px 0;
  text-align: justify;
}

.top {
  background: #ffaa5b;
  border-radius: 8px 8px 0px 0px;
  padding: 9px 12px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: left;
}

.content {
  width: 183px;
  background: #fafcff;
  padding: 9px 12px;
  box-sizing: border-box;
  border: 1px solid #e1e3e6;
  margin-top: -1px;
  font-size: 12px;
  letter-spacing: 1px;
}

.scroll {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.noscroll {
  transform: rotate(0);
  transition: transform 0.3s;
}

.more {
  color: #999999;
  text-align: center;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
}
</style>
